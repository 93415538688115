import React, { Fragment, FunctionComponent } from 'react'
import { BapResultListUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { BapResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/BapResultListContainer'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { BapAutocompleteCategory } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { TaggingPage } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { useViewMode, ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'

export type SuccessResultListProps = {
    searchResult: SearchResult
    searchTerms: SeoSearchTerms[]
    fromExpiredAdId?: string | undefined
    categorySuggestions?: BapAutocompleteCategory[] | undefined
    defaultViewMode: ViewMode
    pageViewEvent?: TaggingPage
}

export const SuccessResultListWrapper: FunctionComponent<SuccessResultListProps> = ({
    categorySuggestions,
    defaultViewMode,
    fromExpiredAdId,
    pageViewEvent,
    searchResult,
    searchTerms,
}) => {
    const [viewMode, setViewMode] = useViewMode(defaultViewMode)

    return (
        <Fragment>
            <BapResultListUserZoom />
            <BapResultListContainer
                pageViewEvent={pageViewEvent}
                searchResult={searchResult}
                searchTerms={searchTerms}
                fromExpiredAdId={fromExpiredAdId}
                initialCategorySuggestions={categorySuggestions}
                viewMode={viewMode}
                setViewMode={setViewMode}
            />
        </Fragment>
    )
}
