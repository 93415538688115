import { getAutocompleteOnlyCategories } from '@bbx/search-journey/common/api/autocompleteApiClient'
import { getSeoSearchResult, getSeoSearchTerms } from '@bbx/search-journey/common/api/searchApiClient'
import { getInitialViewMode, ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import {
    SuccessResultListProps,
    SuccessResultListWrapper,
} from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/SuccessResultListWrapper'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { NextPage } from 'next'
import React from 'react'

export type ResultListProps =
    | SuccessResultListProps
    | {
          is404: true
          defaultViewMode: ViewMode
      }

const ResultList: NextPage<ResultListProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <SuccessResultListWrapper
                searchResult={props.searchResult}
                searchTerms={props.searchTerms}
                fromExpiredAdId={props.fromExpiredAdId}
                categorySuggestions={props.categorySuggestions}
                defaultViewMode={props.defaultViewMode}
            />
        )
    }
}

export const resultListGetInitialProps =
    (basePath: string) =>
    async ({ query, req, res }: ExpressNextContextWithUserData): Promise<ResultListProps> => {
        const { seopath, fromExpiredAdId: rawFromExpiredAdId, ...remainingQuery } = query
        const path = [basePath, ...((seopath ?? []) as string[])].join('/')
        const fromExpiredAdId = getFirstString(rawFromExpiredAdId)

        if (!path) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true, defaultViewMode: 'list' }
        }

        try {
            const concatenatedPath = concatPathWithQueryParams(path, remainingQuery)

            const searchTermsPromise = getSeoSearchTerms(verticalIdMap.BAP, 32)
            const searchResultPromise = getSeoSearchResult(concatenatedPath, req)
            const categorySuggestionsPromise = query.keyword ? getAutocompleteOnlyCategories(`${query.keyword}`) : Promise.resolve([])

            const [searchResult, searchTerms, categorySuggestions] = await Promise.all([
                searchResultPromise,
                searchTermsPromise,
                categorySuggestionsPromise,
            ])

            const defaultViewMode = getInitialViewMode(searchResult, req)

            return { searchResult, searchTerms: [searchTerms], fromExpiredAdId, categorySuggestions, defaultViewMode }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                if (res) {
                    res.statusCode = 404
                }
                return { is404: true, defaultViewMode: 'list' }
            }

            throw apiError
        }
    }

ResultList.getInitialProps = resultListGetInitialProps('/kaufen-und-verkaufen/marktplatz')
ResultList.Layout = LeaderboardAndSkyscraperLayout

export default ResultList
